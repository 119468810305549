"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clubEventInputSchema = exports.clubEventSchema = exports.clubEventGeoLocationTypeSchema = exports.ClubEventGeoLocationType = void 0;
const zod_1 = require("zod");
const services_1 = require("../../services");
const geo_location_1 = require("../geo-location");
var ClubEventGeoLocationType;
(function (ClubEventGeoLocationType) {
    ClubEventGeoLocationType["MEETING_POINT"] = "MEETING_POINT";
    ClubEventGeoLocationType["VENUE"] = "VENUE";
    ClubEventGeoLocationType["REGION"] = "REGION";
})(ClubEventGeoLocationType = exports.ClubEventGeoLocationType || (exports.ClubEventGeoLocationType = {}));
exports.clubEventGeoLocationTypeSchema = zod_1.z.nativeEnum(ClubEventGeoLocationType);
exports.clubEventSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    clubPostId: zod_1.z.string().uuid(),
    program: zod_1.z.string().nullable(),
    geoLocationType: exports.clubEventGeoLocationTypeSchema,
    geoLocation: geo_location_1.geoLocationSchema,
    startAt: zod_1.z.date(),
    finishAt: zod_1.z.date(),
    isAllDay: zod_1.z.boolean(),
    registrationDeadlineAt: zod_1.z.date().nullable(),
    priceRp: zod_1.z.number().int().positive().nullable(),
    maximumParticipants: zod_1.z.number().int().min(2).nullable(),
    numberOfParticipants: zod_1.z.number().int().min(0),
});
(0, services_1.checkSchema)(exports.clubEventSchema);
exports.clubEventInputSchema = exports.clubEventSchema.pick({
    id: true,
    program: true,
    geoLocationType: true,
    geoLocation: true,
    startAt: true,
    finishAt: true,
    isAllDay: true,
    registrationDeadlineAt: true,
    priceRp: true,
    maximumParticipants: true,
});
