"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationAssetsSchema = exports.notificationSchema = exports.notificationTypeSchema = exports.NOTIFICATION_GROUP_BY_TYPE = exports.NOTIFICATION_GROUPS = exports.NotificationGroupType = exports.NotificationType = void 0;
const zod_1 = require("zod");
const club_1 = require("./club");
const user_1 = require("./user");
const translation = (notificationGroups) => Object.entries(notificationGroups).reduce((acc, [group, ntArray]) => {
    ntArray.forEach((nt) => {
        acc[nt] = group;
    });
    return acc;
}, {});
// All enum values should start from NF
// because this enum is also used in ClubSetting, merged with other values
var NotificationType;
(function (NotificationType) {
    NotificationType["NF_CLUB_MEMBER_JOINED"] = "NF_CLUB_MEMBER_JOINED";
    NotificationType["NF_EVENT_REMINDER"] = "NF_EVENT_REMINDER";
    NotificationType["NF_EVENT_DEADLINE_REMINDER"] = "NF_EVENT_DEADLINE_REMINDER";
    NotificationType["NF_NEWS"] = "NF_NEWS";
    NotificationType["NF_GALLERY"] = "NF_GALLERY";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));
var NotificationGroupType;
(function (NotificationGroupType) {
    NotificationGroupType["USER_MEMBERSHIP"] = "USER_MEMBERSHIP";
    NotificationGroupType["EVENTS"] = "EVENTS";
    NotificationGroupType["NEWS"] = "NEWS";
    NotificationGroupType["GALLERY"] = "GALLERY";
})(NotificationGroupType = exports.NotificationGroupType || (exports.NotificationGroupType = {}));
exports.NOTIFICATION_GROUPS = {
    USER_MEMBERSHIP: [NotificationType.NF_CLUB_MEMBER_JOINED],
    EVENTS: [
        NotificationType.NF_EVENT_REMINDER,
        NotificationType.NF_EVENT_DEADLINE_REMINDER,
    ],
    NEWS: [NotificationType.NF_NEWS],
    GALLERY: [NotificationType.NF_GALLERY],
};
exports.NOTIFICATION_GROUP_BY_TYPE = translation(exports.NOTIFICATION_GROUPS);
exports.notificationTypeSchema = zod_1.z.nativeEnum(NotificationType);
exports.notificationSchema = zod_1.z.object({
    title: zod_1.z.string(),
    body: zod_1.z.string(),
    link: zod_1.z.string().nullable(),
});
exports.notificationAssetsSchema = zod_1.z.object({
    club: club_1.clubSchema.pick({ id: true, name: true }),
    user: user_1.userSchema.pick({ id: true, nameFirst: true, nameLast: true }),
});
