"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.adminUserEnvironmentSchema = exports.tokenUserSchema = exports.userPatchSchema = exports.userAdminPatchSchema = exports.userAdminInputSchema = void 0;
const zod_1 = require("zod");
const auth_channel_1 = require("../auth/auth-channel");
const iap_1 = require("../iap");
const user_schema_1 = require("./user-schema");
exports.userAdminInputSchema = user_schema_1.userSchema.omit({
    createdAt: true,
    updatedAt: true,
});
exports.userAdminPatchSchema = exports.userAdminInputSchema.omit({
    id: true,
});
exports.userPatchSchema = user_schema_1.userSchema.omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
    isAdmin: true,
    email: true,
    invisibilityTill: true,
});
exports.tokenUserSchema = user_schema_1.userSchema.pick({
    id: true,
    nameFirst: true,
    nameLast: true,
    isAdmin: true,
    imageId: true,
});
exports.adminUserEnvironmentSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    user: user_schema_1.userSchema,
    authChannels: zod_1.z.array(auth_channel_1.authChannelSchema),
    iapSubscriptions: zod_1.z.array(iap_1.iapSubscriptionSchema),
});
